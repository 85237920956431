<template>
  <AuthLayout>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <h1 class="h3 mb-3 fw-bold text-center">
        <i class="bi bi-person-plus display-3"></i><br />
        KAYIT
      </h1>

      <div class="row mb-3">
        <div class="col-md-6 py-0 px-1">
          <div class="form-floating">
            <Field
              name="first_name"
              as="input"
              type="text"
              class="form-control rounded-0 rounded-start"
              :class="{ 'is-invalid': errors.first_name }"
              id="floatingFirstName"
              placeholder="Adınız"
            />
            <label for="floatingFirstName">Adınız</label>
            <div class="invalid-feedback">{{ errors.first_name }}</div>
          </div>
        </div>
        <div class="col-md-6 py-0 px-1">
          <div class="form-floating">
            <Field
              name="last_name"
              as="input"
              type="text"
              class="form-control rounded-0 rounded-end"
              :class="{ 'is-invalid': errors.last_name }"
              id="floatingLastName"
              placeholder="Soyadınız"
            />
            <label for="floatingLastName">Soyadınız</label>
            <div class="invalid-feedback">{{ errors.last_name }}</div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 py-0 px-1">
          <div class="form-floating">
            <Field
              name="username"
              as="input"
              type="text"
              class="form-control rounded-0 rounded-start"
              :class="{ 'is-invalid': errors.username }"
              id="floatingUsername"
              placeholder="Kullanıcı adınız"
            />
            <label for="floatingUsername">Kullanıcı Adınız</label>
            <div class="invalid-feedback">{{ errors.username }}</div>
          </div>
        </div>
        <div class="col-md-6 py-0 px-1">
          <div class="form-floating">
            <Field
              name="email"
              as="input"
              type="email"
              class="form-control rounded-0 rounded-end"
              :class="{ 'is-invalid': errors.email }"
              id="floatingEmail"
              placeholder="Eposta adresiniz"
            />
            <label for="floatingEmail">Eposta Adresiniz</label>
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 py-0 px-1">
          <div class="form-floating">
            <Field
              name="password1"
              as="input"
              type="password"
              class="form-control rounded-0 rounded-start"
              :class="{ 'is-invalid': errors.password1 }"
              id="floatingPassword1"
              placeholder="Parola"
            />
            <label for="floatingPassword1">Parola</label>
            <div class="invalid-feedback">{{ errors.password1 }}</div>
          </div>
        </div>
        <div class="col-md-6 py-0 px-1">
          <div class="form-floating">
            <Field
              name="password2"
              as="input"
              type="password"
              class="form-control rounded-0 rounded-end"
              :class="{ 'is-invalid': errors.password2 }"
              id="floatingPassword2"
              placeholder="Parola"
            />
            <label for="floatingPassword2">Parola Doğrula</label>
            <div class="invalid-feedback">{{ errors.password2 }}</div>
          </div>
        </div>
      </div>
      <button class="w-100 btn btn-lg btn-primary mt-4" type="submit">
        Kayıt Ol
      </button>
    </Form>
    <div class="d-flex justify-content-evenly mt-3">
      <router-link class="btn p-0" to="/login"
        >Zaten bir hesabın var mı?</router-link
      >
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/Auth.layout.vue";

import { Field, Form } from "vee-validate";
import * as Yup from "yup";

import { mapActions } from "vuex";

export default {
  name: "Register",
  components: { AuthLayout, Field, Form },
  methods: {
    ...mapActions("account", ["register"]),
    onSubmit(values) {
      this.register({ user: values });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      first_name: Yup.string().required("Zorunlu alan!"),
      last_name: Yup.string().required("Zorunlu alan!"),
      username: Yup.string()
        .max(150, "En fazla 150 karakter olmalıdır!")
        .required("Zorunlu alan!"),
      email: Yup.string()
        .email("Yanlış eposta formatı!")
        .required("Zorunlu alan!"),
      password1: Yup.string()
        .matches(
          /^.*(?=.*[a-zA-Z\D])(?=.*\d).*$/,
          "Karmaşıklık gereksinimi karşılanmadı. En az 1 rakam ve en az 1 rakam olmayan karakter içermelidir!"
        )
        .min(8, "En 8 karakter olmalıdır!")
        .required("Zorunlu alan!"),
      password2: Yup.string()
        .oneOf([Yup.ref("password1"), null], "Parolalar eşleşmiyor!")
        .required("Zorunlu alan!"),
    });

    return {
      schema,
    };
  },
};
</script>

<style></style>
